import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import ContentBlok from '#app/components/storyblok/content-blok'
import { type MarketingImageCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'

const MarketingImageCard = ({ blok }: MarketingImageCardStoryblok) => {
	const renderContentBlock = (block: any, index: number) => {
		return (
			<ContentBlok
				blok={block}
				key={`${block._uid}-${index}`}
				_uid={''}
				component={'contentBlok'}
			/>
		)
	}

	const contentBlok = Array.isArray(blok.contentBlok) ? blok.contentBlok : []
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative overflow-hidden rounded-[24px] bg-cover bg-center bg-no-repeat"
		>
			<Link to={blok.linkToPage} className="z-10">
				<img
					alt="genderImage"
					className=""
					src={blok.image?.filename}
					fetchpriority="high"
				/>
				<BlackBackgroundColorForImages />

				<div className="absolute bottom-6 left-6 flex flex-col">
					{contentBlok.map(renderContentBlock)}
				</div>
			</Link>
		</div>
	)
}

export default MarketingImageCard
