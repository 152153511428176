import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { useRef, useState, useEffect } from 'react'
import { type CardListStoryblok } from '#types/component-types-sb'
import { Button } from '../ui/button'
import { Icon } from '../ui/icon'

const CardList = ({ blok }: CardListStoryblok) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [showLeftArrow, setShowLeftArrow] = useState(false)
	const [showRightArrow, setShowRightArrow] = useState(false)
	let scrollTimeout = useRef<NodeJS.Timeout | null>(null)

	const checkScrollButtons = () => {
		const { current } = scrollContainerRef
		if (current) {
			const maxScrollLeft = current.scrollWidth - current.clientWidth
			setShowLeftArrow(current.scrollLeft > 0)
			setShowRightArrow(current.scrollLeft < maxScrollLeft)
		}
	}

	const handleScroll = () => {
		setShowLeftArrow(false)
		setShowRightArrow(false)

		if (scrollTimeout.current !== null) {
			clearTimeout(scrollTimeout.current)
		}
		scrollTimeout.current = setTimeout(() => {
			checkScrollButtons()
		}, 150)
	}

	const scroll = (scrollOffset: number) => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				left: scrollContainerRef.current.scrollLeft + scrollOffset,
				behavior: 'smooth',
			})
		}
	}

	useEffect(() => {
		const { current } = scrollContainerRef
		if (current) {
			current.addEventListener('scroll', handleScroll, { passive: true })
			checkScrollButtons()
		}
		return () => {
			if (current) {
				current.removeEventListener('scroll', handleScroll)
			}
			if (scrollTimeout.current) {
				clearTimeout(scrollTimeout.current)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blok.cards])

	return (
		<div className="relative mx-[-1rem] md:mx-[-0rem]">
			{showLeftArrow && (
				<Button
					variant={'secondary'}
					onClick={() => scroll(-1000)}
					className={`scroll-button-styles group absolute left-[48px] top-[80px] z-10 hidden rounded-full border border-black bg-white px-4 py-[4px] ${
						showLeftArrow ? 'md:flex' : 'md:hidden'
					}`}
				>
					<Icon
						className="text-body-md text-black group-hover:text-white"
						size="lg"
						name="left-arrow"
					/>
				</Button>
			)}
			<div
				ref={scrollContainerRef}
				className="smooth-scroll flex gap-2 overflow-x-auto px-4 scrollbar-hide  md:gap-4 md:px-0"
				{...storyblokEditable(blok)}
				key={blok._uid}
			>
				{blok.cards.map((blokCard: SbBlokData) => (
					<StoryblokComponent key={blokCard._uid} blok={blokCard} />
				))}
			</div>
			{showRightArrow && (
				<Button
					variant={'secondary'}
					onClick={() => scroll(1000)}
					className={`scroll-button-styles group absolute right-[48px] top-[80px] z-10 hidden rounded-full border border-black bg-white px-4 py-[4px] ${
						showRightArrow ? 'md:flex' : 'md:hidden'
					}`}
				>
					<Icon
						className="text-body-md text-black group-hover:text-white"
						size="lg"
						name="right-arrow"
					/>
				</Button>
			)}
		</div>
	)
}

export default CardList
