import { Heading } from '#app/components/typography/index'
import { Icon } from '#app/components/ui/icon'

export const PageIsDown = () => {
	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
			<div className="text-center">
				<Heading as="h5" className="mb-8 ">
					Undskyld - vi er nede for øjeblikket, vi arbejder på problemet
				</Heading>
			</div>
			<div className="relative w-fit">
				<Icon
					name="bubbles"
					size="font"
					className="h-[95px] w-[155px] text-mint-green-80p md:h-[200px] md:w-[300px]"
				/>
				<div className="absolute left-[50%] top-[50%] w-full -translate-x-[50%] -translate-y-[50%] text-center">
					<img src="/img/sad404.svg" alt="error icon" />
				</div>
			</div>
		</div>
	)
}

export default PageIsDown
