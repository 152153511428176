import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { type GridColumnsStoryblok } from '#types/component-types-sb'

const GridColumns = ({ blok }: GridColumnsStoryblok) => {
	const numberOfColumns = parseInt(blok.numberOfColumns, 10)
	const actAsSliderOnMobile = blok.ActAsSliderOnMobile
	const columnGap = blok.columnGap || 'gap-4' // Default gap size if not specified

	let containerClasses = `${
		actAsSliderOnMobile
			? `flex overflow-x-auto snap-x snap-mandatory ${columnGap} scrollbar-hide`
			: `grid place-items-center ${columnGap}`
	}`

	let itemClasses = `flex w-full items-center justify-center`

	if (!actAsSliderOnMobile) {
		switch (numberOfColumns) {
			case 2:
				containerClasses +=
					' md:grid-cols-2 mx-auto place-items-center sm:justify-center md:flex md:items-start md:justify-between'
				break
			case 3:
				containerClasses +=
					'  grid md:grid-cols-3 grid-cols-1 mx-auto place-items-center gap-x-4 md:gap-x-6'
				break
			case 4:
				containerClasses +=
					' md:grid-cols-2 justify-center lg:grid-cols-4 mx-auto place-items-center md:flex md:items-start md:justify-between'
				break
			case 5:
				containerClasses +=
					' md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mx-auto place-items-center sm:justify-center md:flex md:items-start md:justify-between'
				break
			case 6:
				containerClasses +=
					' md:grid-cols-3 2xl:grid-cols-4  mx-auto place-items-center sm:justify-center md:flex md:items-start md:justify-between'
				break
			case -1:
				containerClasses +=
					' md:grid-cols-3  2xl:grid-cols-4 grid-cols-1 mx-auto place-items-center gap-x-4 md:gap-x-6'
				break
			default:
				containerClasses += ' grid-cols-1'
				break
		}
	}

	return (
		<ul
			{...storyblokEditable(blok)}
			key={blok._uid}
			className={containerClasses}
		>
			{blok.columns.map((blokColumn: SbBlokData) => (
				<li
					key={blokColumn._uid}
					className={itemClasses}
					style={{ minWidth: '124px' }}
				>
					<StoryblokComponent blok={blokColumn} />
				</li>
			))}
		</ul>
	)
}

export default GridColumns
