import { storyblokEditable } from '@storyblok/react'
import Body from '#app/components/typography/body'
import { type BodyStoryblok } from '#types/component-types-sb'
const BodyBlok = ({ blok }: BodyStoryblok) => {
	return (
		<Body
			{...storyblokEditable(blok)}
			key={blok._uid}
			as={blok.as}
			body={blok.body}
			weight={blok.weight}
			textColor={blok.textColor}
		/>
	)
}

export default BodyBlok
