import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { cn } from '#app/utils/misc'
import { type SectionStoryblok } from '#types/component-types-sb'

const Section = ({ blok }: SectionStoryblok) => {
	//Hacky as fuck but only way to make the design since there is no consistency in how much padding top and bottom there is across the website
	const sectionId = `section-${blok._uid}`
	const backgroundColor = blok.backgroundColor || 'transparent'
	const bgPillImageClass = blok.showSinglePill ? 'bg-pill-mobile' : ''
	const bgTriplePillImageClass = blok.showTriplePill ? 'bg-triple-pill' : ''

	const paddingTopMobile = blok.paddingTopMobile
		? `${blok.paddingTopMobile}px`
		: '0'
	const paddingBottomMobile = blok.paddingBottomMobile
		? `${blok.paddingBottomMobile}px`
		: '0'
	const paddingTopDesktop = blok.paddingTopDesktop
		? `${blok.paddingTopDesktop}px`
		: paddingTopMobile
	const paddingBottomDesktop = blok.paddingBottomDesktop
		? `${blok.paddingBottomDesktop}px`
		: paddingBottomMobile
	const dynamicStyles = `
		#${sectionId} {
		  padding-top: ${paddingTopMobile};
		  padding-bottom: ${paddingBottomMobile};
		}
		@media (min-width: 820px) {
		  #${sectionId} {
			padding-top: ${paddingTopDesktop};
			padding-bottom: ${paddingBottomDesktop};
		  }
		}
	  `

	return (
		<>
			<style>{dynamicStyles}</style>
			<div
				{...storyblokEditable(blok)}
				id={sectionId}
				className={cn(
					`relative px-4 ${bgPillImageClass} ${bgTriplePillImageClass}`,
					blok.fullWidthRight
						? 'md:pl-[var(--plenty-padding)] md:pr-0'
						: 'w-full md:px-[var(--plenty-padding)]',
				)}
				style={{
					backgroundColor,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'left top',
					// Do not set paddingTop or paddingBottom here
				}}
			>
				<div
					className={cn(
						`w-full`,
						blok.fullWidthRight ? 'md:ml-auto ' : 'mx-auto ',
					)}
				>
					{blok.subSections.map((blokSub: SbBlokData) => (
						<div key={blokSub._uid}>
							<StoryblokComponent blok={blokSub} />
						</div>
					))}
				</div>
			</div>
		</>
	)
}

export default Section
