import { render } from 'storyblok-rich-text-react-renderer';
import Body from '#app/components/typography/body'
import { Accordion,	AccordionContent, AccordionItem, AccordionTrigger } from '#app/components/ui/accordion'
import { type AccordionStoryblok } from "#types/component-types-sb"

const AccordionBlok = ({ blok }: AccordionStoryblok) => {

    return(
        <div className="flex">
            <Accordion type="multiple" className="w-full">
                <AccordionItem className=" border-b pb-2" value="color">
                    <AccordionTrigger className="flex text-left items-left">
                        <Body
                            as="m"
                            body={blok.accordion_label}
                            className="items-left"
                        />
                    </AccordionTrigger>
                    <AccordionContent className="py-2 text-start flex flex-col flex-w-full">
                        {render(blok.accordion_body)}
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )

}

export default AccordionBlok