import { Link } from "@remix-run/react"
import { type LinkedTextStoryblok } from "#types/component-types-sb"
import { Body } from "../typography"

const LinkedText = ({blok}: LinkedTextStoryblok) => {
    return (
        <div className="flex md:flex-row flex-col md:gap-1">
            {blok.bodyText && <Body key={blok.bodyText._uid}
                as={blok.as}
                body={blok.bodyText}
                weight={blok.weight}
                textColor={blok.textColor}
            />}
            <Link to={blok.link.url}>
                <Body key={blok.linkText._uid}
                    as={blok.as}
                    body={blok.linkText}
                    weight={blok.weight}
                    textColor={blok.textColor}
                    className="underline"
                />
            </Link>
        </div>        
    )
}

export default LinkedText