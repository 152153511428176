import { Link, useLoaderData } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useTranslation } from 'react-i18next'
import { type loader } from '#app/routes/_public+/$'
import { type StoreCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import { Body } from '../typography'
import ContentBlok from './content-blok'

const StoreCard = ({ blok }: StoreCardStoryblok) => {
	const { allOpeningTimes } = useLoaderData<typeof loader>()
	const storeOpeningTimes = allOpeningTimes.find(
		(times: { full_slug: string }) =>
			times.full_slug === blok.slugForShowingOpeningTime,
	)

	let { t } = useTranslation('store_page')

	const daysOfWeek = [
		t('common:sunday_tag'),
		t('common:monday_tag'),
		t('common:tuesday_tag'),
		t('common:wednesday_tag'),
		t('common:thursday_tag'),
		t('common:friday_tag'),
		t('common:saturday_tag'),
	]

	const todayIndex = new Date().getDay()
	var todayName = daysOfWeek[todayIndex]
	const todayOpeningHours = storeOpeningTimes?.content?.openingHours?.find(
		(oh: { day: string }) => oh.day === todayName,
	)

	const isOpenNow = (openingHours: {
		openingTime: string
		closingTime: string
	}) => {
		const now = new Date()
		const openingTimeToday = new Date()
		const closingTimeToday = new Date()

		const [openingHour, openingMinute] = openingHours.openingTime
			.split(':')
			.map(Number)
		const [closingHour, closingMinute] = openingHours.closingTime
			.split(':')
			.map(Number)

		openingTimeToday.setHours(openingHour, openingMinute, 0, 0)
		closingTimeToday.setHours(closingHour, closingMinute, 0, 0)

		const isOpen = now >= openingTimeToday && now < closingTimeToday
		const isBeforeOpening = now < openingTimeToday
		const isClosingSoon =
			now >= new Date(closingTimeToday.getTime() - 60 * 60 * 1000) &&
			now < closingTimeToday // Checks if it's within the last hour before closing

		return { isOpen, isBeforeOpening, isClosingSoon }
	}

	const findNextOpeningDay = () => {
		if (!storeOpeningTimes?.content?.openingHours)
			return t('opening_hours_not_available')

		const tomorrowIndex = (todayIndex + 1) % 7
		for (let i = 0; i < 7; i++) {
			const nextDayIndex = (tomorrowIndex + i) % 7
			const nextDayName = daysOfWeek[nextDayIndex]
			const nextOpeningDay = storeOpeningTimes.content.openingHours.find(
				(oh: { day: string }) => oh.day === nextDayName,
			)
			if (nextOpeningDay) {
				return t('opens_at', { opening_time: nextOpeningDay.openingTime })
			}
		}
		return t('next_opening_day_not_found')
	}
	const renderTodayOpeningHours = () => {
		if (!todayOpeningHours) {
			return (
				<Body as="l" className="w-full items-start">
					{t('today_opening_not_available')}
				</Body>
			)
		}

		const { isOpen, isBeforeOpening, isClosingSoon } =
			isOpenNow(todayOpeningHours)
		let statusText = isOpen ? t('open_tag') : t('closed_tag')
		let statusColor = isOpen ? 'text-mint-green-60' : 'text-red'
		let openingTimeText = t('opens_at', {
			opening_time: todayOpeningHours.openingTime,
		})
		let closingTimeText = t('closes_at', {
			closing_time: todayOpeningHours.closingTime,
		})
		let nextOpeningTimeText = findNextOpeningDay()

		// Adjust display logic based on the store's status
		let additionalInfoText = ''
		if (isOpen) {
			additionalInfoText = closingTimeText // Show closing time if open
			if (isClosingSoon) {
				statusText = t('closes_soon')
				statusColor = 'text-orange-90'
			}
		} else {
			if (isBeforeOpening) {
				additionalInfoText = openingTimeText // Show opening time if before opening
			} else {
				additionalInfoText = nextOpeningTimeText // Show next opening time if after closing
			}
		}

		return (
			<div className="flex w-full items-start gap-2">
				<Body as="l" className={`${statusColor}`} body={statusText} />
				{additionalInfoText && (
					<>
						· <Body as="l" body={additionalInfoText} />
					</>
				)}
			</div>
		)
	}

	const renderContentBlock = (block: any, index: number) => {
		return (
			<ContentBlok
				blok={block}
				key={`${block._uid}-${index}`}
				_uid={''}
				component={'contentBlok'}
			/>
		)
	}

	const contentBlok = Array.isArray(blok.contentBlok) ? blok.contentBlok : []

	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex flex-col items-center"
		>
			<Link to={blok.linkToPage} className="z-10">
				<div className="group relative flex w-full max-w-[500px] overflow-hidden rounded-3xl">
					{' '}
					<img
						src={blok.storeImage?.filename}
						alt="brand"
						className="h-auto w-full object-cover"
					/>
					<BlackBackgroundColorForImages />
					<div className="absolute bottom-5 left-0 z-10 flex h-1/5 w-2/5 items-center justify-center rounded-r-[32px] bg-white  p-2">
						<img
							src={blok.storeNameImage?.filename}
							alt="storeName"
							className=""
						/>
					</div>
				</div>
			</Link>
			<div className="flex w-full flex-col items-start justify-start">
				<div className="flex flex-col">
					{contentBlok.map(renderContentBlock)}
				</div>
			</div>
			{renderTodayOpeningHours()}
		</div>
	)
}

export default StoreCard
