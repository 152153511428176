import { storyblokEditable } from '@storyblok/react'
import { type PerkWithIconStoryblok } from '#types/component-types-sb'
import { Body, Heading } from '../typography'

const PerkWithIcon = ({ blok }: PerkWithIconStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex flex-col items-center gap-2"
		>
			<img src={blok.icon.filename} alt="icon" />
			<Heading as="h7" className="text-[24px] text-white">
				{blok.headingText}
			</Heading>
			<Body as="m" className="text-white">
				{blok.bodyText}
			</Body>
		</div>
	)
}

export default PerkWithIcon
