import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { type AlignContainerStoryblok } from '#types/component-types-sb'

// AlignContainer component
//Everything has to be centered on mobile so just made this md for the desktop version since that can be left, right or center
const AlignContainer = ({ alignment, content }: any) => {
	let alignmentClasses = 'flex'
	switch (alignment) {
		case 'left':
			alignmentClasses =
				'flex md:justify-start justify-center md:flex-row flex-col gap-4'
			break
		case 'right':
			alignmentClasses =
				'flex md:justify-end justify-center md:flex-row flex-col gap-4'
			break
		case 'center':
			alignmentClasses =
				'flex justify-center md:flex-row flex-col gap-4 w-full text-center'
			break
		case 'cdml':
			alignmentClasses =
				'flex justify-center md:justify-start text-left md:text-center flex-col gap-4'
			break
		case 'twoButtonsFullscreenImage':
			alignmentClasses =
				'flex md:justify-start justify-center w-full flex-row gap-4'
			break
		default:
			break
	}

	return (
		<div className={alignmentClasses}>
			{content.map((item: any, index: number) => (
				<StoryblokComponent key={index} blok={item} />
			))}
		</div>
	)
}

// AlignContainerBlok component
const AlignContainerBlok = ({ blok }: AlignContainerStoryblok) => {
	return (
		<AlignContainer
			{...storyblokEditable(blok)}
			alignment={blok.alignment}
			content={blok.content}
		/>
	)
}

export default AlignContainerBlok
