import { storyblokEditable, renderRichText } from '@storyblok/react'
import Heading from '#app/components/typography/heading'
import { type UnorderedListStoryblok } from '#types/component-types-sb'

const UnorderedList = ({ blok }: UnorderedListStoryblok) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex flex-col justify-start gap-6 "
		>
			<Heading as={'h7'} heading={blok.titleText} />
			<div
				className="list flex flex-col gap-2 text-[16px] leading-[1.375rem] tracking-[-0.24px] text-black-90 opacity-70 "
				dangerouslySetInnerHTML={{
					__html: renderRichText(blok?.list),
				}}
			></div>
		</div>
	)
}
export default UnorderedList
