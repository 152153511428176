import { useNavigate } from '@remix-run/react'
import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { type HorizontalStackStoryblok } from '#types/component-types-sb'

const HorizontalStack = ({ blok }: HorizontalStackStoryblok) => {
	const navigate = useNavigate()

	const handleNavigate = (url: string) => {
		if (url) {
			navigate(url)
		}
	}
	const stackStyle = {
		gap: blok.gap ? `${blok.gap}px` : '16px', // Defaulting to 16px if blok.gap is not defined
	}

	return (
		<div
			className="flex cursor-pointer items-center  overflow-x-auto scrollbar-hide"
			{...storyblokEditable(blok)}
			style={stackStyle}
			key={blok._uid}
		>
			{blok.elements.map((blokElement: SbBlokData) => (
				<div
					key={blokElement._uid}
					onClick={() => handleNavigate(blok.navigateToOtherPage)}
				>
					<StoryblokComponent blok={blokElement} />
				</div>
			))}
		</div>
	)
}

export default HorizontalStack
