import { storyblokEditable } from '@storyblok/react'
import Body from '#app/components/typography/body'
import { type BodyBackgroundTextStoryblok } from '#types/component-types-sb'
const BodyBackgroundTextBlok = ({ blok }: BodyBackgroundTextStoryblok) => {
	const textBackgroundStyles = blok.backgroundTextColor
		? {
				backgroundColor: `${blok.backgroundTextColor}`, 
				borderRadius: '0.5rem',
				padding: '0.25rem', 
		  }
		: {}
	const content = (
		<div {...storyblokEditable(blok)} key={blok._uid} className="inline-block">
		
			<Body as={blok.as} weight={blok.weight} textColor={blok.textColor}>
				<span style={textBackgroundStyles}>{blok.body}</span>
			</Body>
		</div>
	)
	return content
}
export default BodyBackgroundTextBlok
