import { type FullScreenImageCenteredTextStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import { Heading } from '../typography'
// TODO: This is a component we gonna delete when we have time to make the search in the brands and store page.
const FullScreenImageCenteredTextBlok = ({
	blok,
}: FullScreenImageCenteredTextStoryblok) => {
	const imageUrlDesktop = blok.FullscreenImageDesktop?.filename || ''
	const imageUrlMobile = blok.FullscreenImageMobile?.filename || imageUrlDesktop
	const minHeightClass = blok.minHeightOf250px ? 'min-h-[250px]' : ''

	const paddingClasses = blok.paddingY ? 'md:py-28 py-14' : ''

	return (
		<div className={`w-full ${paddingClasses} relative overflow-hidden`}>
			<picture className="h-full w-full">
				{imageUrlMobile && (
					<source media="(max-width: 819px)" srcSet={imageUrlMobile} />
				)}
				{imageUrlDesktop && (
					<source media="(min-width: 820px)" srcSet={imageUrlDesktop} />
				)}
				<img
					src={imageUrlDesktop}
					alt=""
					className={`max-h-[600px] w-full object-cover ${minHeightClass}`}
				/>
			</picture>
			{/* Overlay div here, if needed */}
			<BlackBackgroundColorForImages/>
			{!imageUrlDesktop && <p>Image not available</p>}
			<Heading
				as="h3"
				className="absolute inset-0 flex items-center justify-center text-white"
			>
				{blok.text}
			</Heading>
		</div>
	)
}

export default FullScreenImageCenteredTextBlok
